<template>
	<main class="page-container" id="join-intro">
		<section class="mbrp-section">
			<div class="container">
				<header class="mbrp-header">
					<h2 class="title">통합 회원 가입하기</h2>
				</header>
				<div class="mbrp-body">
					<!-- steps -->
					<div class="steps-wrapper">
						<ol class="steps">
							<li class="steps-segment is-complete">
								<div class="steps-inner">
									<span class="steps-marker">
										<span class="icon">1</span>
									</span>
									<div class="steps-content">
										<p class="heading">약관동의</p>
									</div>
								</div>
							</li>
							<li class="steps-segment is-active">
								<div class="steps-inner">
									<span class="steps-marker">
										<span class="icon">2</span>
									</span>
									<div class="steps-content">
										<p class="heading">기업회원 인증</p>
									</div>
								</div>
							</li>
							<li class="steps-segment">
								<div class="steps-inner">
									<span class="steps-marker">
										<span class="icon">3</span>
									</span>
									<div class="steps-content">
										<p class="heading">회원정보 입력</p>
									</div>
								</div>
							</li>
							<li class="steps-segment">
								<div class="steps-inner">
									<span class="steps-marker">
										<span class="icon">4</span>
									</span>
									<div class="steps-content">
										<p class="heading">회원가입 완료</p>
									</div>
								</div>
							</li>
						</ol>
					</div>
					<!-- //steps -->

					<div class="fp-biz-buttons">
						<button type="button" class="fp-biz-button" :class="{ 'is-active': !isBiz }" @click.prevent="isBiz = false">개인 사업자 가입</button>
						<button type="button" class="fp-biz-button" :class="{ 'is-active': isBiz }" @click.prevent="isBiz = true">법인 사업자 가입</button>
					</div>

					<!-- s: 개인 사업자 가입 -->
					<div v-if="!isBiz">
						<div class="mbrp-cert-wrap display-flex">
							<div class="flex-l">
								<div class="field field-row">
									<div class="field-label w120"><label for="">사업자 등록번호</label></div>
									<div class="control field-form">
										<input v-model="prtlBrno" type="text" class="input" placeholder="사업자 등록번호를 입력하세요 (ex 1120435945)" />
									</div>
								</div>
								<div class="field field-row">
									<div class="field-label w120"><label for="">대표자명</label></div>
									<div class="control field-form">
										<input v-model="ceoNm" type="text" class="input" placeholder="대표명을 입력하세요" />
									</div>
								</div>
								<div class="field field-row">
									<div class="field-label w120"><label for="">개업일자</label></div>
									<div class="control field-form">
										<input v-model="openDay" type="text" class="input" placeholder="개업일자를 입력하세요 (ex 20100705)" />
									</div>
								</div>
							</div>
							<div class="flex-r">
								<button type="button" class="button-default is-secondary" @click="compCheck">인증하기</button>
							</div>
						</div>

						<div class="mbrp-cert-desc">
							<p>※유의사항</p>
							<p>
								사업자 등록번호가 인증되지 않은 사용자의 경우 통합기업회원으로 가입할 수 없으며 이에 따라 포털의 기능을 모두 사용할 수 없습니다. (커뮤니티, e 러닝, 시설예약 등 기능 사용 불가)<br />
								사업자 등록번호가 없는 경우 일반회원으로 가입하시기 바랍니다.
							</p>
							<router-link to="/join/general" class="button-general is-secondary">일반회원 가입 바로가기</router-link>
						</div>

						<div class="buttons text-center">
							<button type="button" class="button-default is-large is-primary is-rounded" @click="clickNext()">다음 단계</button>
						</div>
					</div>
					<!-- e: 개인 사업자 가입 -->
					<!-- s: 법인 사업자 가입 -->
					<div v-else>
						<div class="mbrp-cert-desc is-padding text-center">법인 사업자의 경우 판판대로(<a href="https://fanfandaero.kr/portal/joinUser.do" target="_blank" title="새창열림">https://fanfandaero.kr/portal/main.do</a>)에서 회원가입 가능합니다.</div>
						<div class="buttons text-center">
							<a href="https://fanfandaero.kr/portal/joinUser.do" class="button-default is-large is-primary is-rounded" target="_blank" title="새창열림">확인(판판대로 이동)</a>
						</div>
					</div>
					<!-- e: 법인 사업자 가입 -->
				</div>
			</div>
		</section>
	</main>
</template>
<script>
import { mapGetters } from 'vuex';
import DropDown from '@/components/common/DropDown';
import {ACT_CHECK_COMP_INFO, ACT_CHECK_SSO_DUPLICATE_BRNO, ACT_REMOVE_AUTH_TOKEN, ACT_INSERT_SSO_USER_TRANS_MAPPING} from "@/store/_act_consts";
import {getItem, isSuccess} from "@/assets/js/utils";
import {MUT_SHOW_ALERT, MUT_SHOW_CONFIRM, MUT_SET_RETURN_ROUTE} from '@/store/_mut_consts';
import update from "swiper/src/components/core/update";

export default {
  name: 'JoinIntegratedCert',
  components: {},
  computed: {
    ...mapGetters('auth', ['session', 'isAuth']),
    ...mapGetters('common', ['isMobile']),
    ...mapGetters('join', ['bzInfo']),
  },
  watch: {
		prtlBrno() {
			this.bizInfo.isCompCheck = false; //변경 시 진위여부 리셋
		},
		openDay() {
			this.bizInfo.isCompCheck = false; //변경 시 진위여부 리셋
		},
		ceoNm() {
			this.bizInfo.isCompCheck = false; //변경 시 진위여부 리셋
		},
	},
  data: () => ({
    isBiz: false,
    bizInfo: {
      brno: '', //사업자등록번호
      openDay: '', //개업일자
      ceoNm: '', //대표명
      compNm: '', //기업명
      isCompCheck: false,
    },
		isDuplicateCheck: false,
		prtlBrno: '', //사업자등록번호
		openDay: '', //개업일자
		ceoNm: '', //대표명
  }),
  mounted() {},
  methods: {
    clickNext() {
      // 필수 데이터 검증
      // 사업자 인증
      // 성공 시 스토어의 값 업데이트
      // 실패 시 팝업
			if (this.bizInfo.isCompCheck && this.isDuplicateCheck) {
				this.$router.push({ name: 'JoinIntegratedForm' });
			}
      else this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
        title: `사업자 인증을 진행해주세요.`,
      });
    },		
		checkParams() {
			let msg = '';
			let result = false;

			if (!this.prtlBrno) {
				msg = '사업자번호를 입력해주세요.';
			} else if (!this.ceoNm) {
				msg = '대표자명을 입력해주세요.';
			} else if (!this.openDay) {
				msg = '개업일자를 입력해주세요.';
			} else {
				result = true;
			}

			if (!result) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: msg,
					html: true,
				});
			}
			return result;
		},
    compCheck() {
      const payload = {
        prtlBrno: this.prtlBrno, //사업자등록번호
        openDay: this.openDay, //개업일자
        ceoNm: this.ceoNm, //대표명
        // compNm: this.bizInfo.compNm
      };

			if(!this.checkParams()){
				return;
			}

			// 테스트용 임시
			// this.bizInfo.isCompCheck = true;

			// if (this.bizInfo.isCompCheck) {
			// 	// this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
			// 	//   title: `인증되었습니다.`,
			// 	// });
			// 	//const item = getItem(res);
			// 	this.bizInfo.brno = this.prtlBrno;
			// 	this.bizInfo.openDay = this.openDay;
			// 	this.bizInfo.ceoNm = this.ceoNm;
			// 	this.updateBzInfo(this.bizInfo);
			// 	this.ssoDuplicateBrno();
			// }
			// 테스트용 임시

      this.$store.dispatch(`auth/${ACT_CHECK_COMP_INFO}`, payload).then((res) => {
        //const item = getItem(res);
        // this.isCompCheck = res.status.code === 200;
        //this.bizInfo.isCompCheck = item.data[0].valid === '01'
				this.bizInfo.isCompCheck = isSuccess(res);
        if (this.bizInfo.isCompCheck) {
          // this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
          //   title: `인증되었습니다.`,
          // });
          //const item = getItem(res);
					this.bizInfo.brno = this.prtlBrno;
					this.bizInfo.openDay = this.openDay;
					this.bizInfo.ceoNm = this.ceoNm;
         this.updateBzInfo(this.bizInfo);
					this.ssoDuplicateBrno();
        } else {
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
            //title: item.data[0].valid_msg,
						title: `해당하는 회사정보가 없습니다. 입력한 정보가 맞는지 확인해주세요.`,
          });
        }
      });
    },
		ssoDuplicateBrno(){
			this.$store.dispatch(`auth/${ACT_CHECK_SSO_DUPLICATE_BRNO}`, this.prtlBrno).then((res) => {
				const success = isSuccess(res);
				if(success){
					const count = res.itemsCount;
					const ssoId = res.ssoId;
					if (count > 0 && ssoId != '') {	// 기존에 통합DB에 등록된 데이터와 매핑
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
							title: `해당 사업자번호로 이미 통합 등록된 유저 정보가 이미 있습니다.`,
							yesfunc: () => {
								this.$router.push({ name: 'Login' });										//로그인 화면으로 이동
							},
						});
					} else {
						
					}
				} else {
					if(res.result.number === 407){ // 이미 포탈에 통합 DB와 매핑된 유저정보가 존재함. 모든 프로세스 이탈.
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
							title: `해당 사업자번호로 이미 인증된 유저 정보가 존재합니다.`,
							yesfunc: () => {
								this.$router.push({ name: 'Login' });										//로그인 화면으로 이동
							},
						});
					} else if(res.result.number === 403){ // 통합DB 에 NOT FOUND. 등록 가능 상태.
						this.isDuplicateCheck = true;
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
							title: `인증되었습니다.`,
						});
					} else {
						this.isDuplicateCheck = false;
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
							title: `사업자 인증 체크 불가`,
						});
					}
				}
			});
		},
    updateBzInfo(bzInfo) {
      this.$store.dispatch('join/updateBzInfo', bzInfo)
    }
  },
  destroyed() {},
};
</script>
